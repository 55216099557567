<template>
  <div class="nav-bar">
    <div class="my home" @click="onMy" v-if="showMyHome"></div>
    <!-- <div class="back" @click="onBack" v-if="showBack"></div> -->
    <van-icon name="arrow-left" class="back" @click="onBack" v-if="showBack" />

    <div class="content">
      <slot></slot>
    </div>

    <div class="share" @click="onShare" v-if="showShare"></div>
    <div class="my" @click="onMy" v-if="showMy"></div>
  </div>
</template>

<script>
  export default {
    props: ['showBack', 'showShare', 'showMy', 'showMyHome'],
    methods: {
      onBack() {
        this.gotoPage('back')
      },
      onShare() {
        this.$bus.$emit('share', true)
      },
      onMy() {
        this.gotoPage('my')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .nav-bar {
    width: 100%;
    height: pxTo(52);
    background-color: #fff;
    color: #000;
    position: relative;
   // border-bottom: 1px solid rgba(206, 228, 7, 0.932);
    .content {
      width: 100%;
      height: pxTo(52);
    //   background-color: $c1;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 10;
    }

    .back, .share, .my {
      width: pxTo(24);
      height: pxTo(24);
      z-index: 10+1;
      position: fixed;
      top: pxTo((52-24)/2)
    }

    .back {
      left: pxTo(16);
      width: pxTo(17);
      height: pxTo(17);
      top: pxTo((52-17)/2);
      font-size: 22px;

      @include bg($img-base, 'ic_arrow_back.svg');
      background-size: auto 100%;
    }

    .share {
      right: pxTo(16);
      width: pxTo(21);
      height: pxTo(21);
      top: pxTo((52-21)/2);

      @include bg($img-base, 'ic_share.svg');
      background-size: 100% 100%;
    }

    .my {
      right: pxTo(16);

      @include bg($img-base, 'ic_my_groups.svg');
      background-size: 100% 100%;

      &.home {
        right: auto;
        left: pxTo(16);
      }
    }
  }
</style>