<template>
    <div class="orderDetail-container">
        <nav-bar show-back="1">
            <div class="nav-title">Status Pemesanan</div>
        </nav-bar>
        <div class="status-detail">
            <span class="status">
                {{formatOrderStatus(this.orderData.status)}}
            </span>
            <span class="desc">
                Perintah ditempatkan. Silakan tunggu pengiriman
            </span>
        </div>
        <div class="address-info">
            <div class="name_phone">{{`${addressData.receiver} ${addressData.mobile}`}}</div>
            <span>{{addressData.detail_address}}</span>
            <div class="desc_info">{{`${addressData.receiver} ${addressData.mobile}`}}</div>
        </div>
        <div class="good_warp">
            <div class="good_content">
                <div class="img-container">
                    <img :src="orderData.good_thumbnail" alt="">
                </div>
                <div class="title">{{orderData.good_title}}</div>
                <div class="price">
                    <span>Rp</span>
                    <span>{{`${orderData.goodPrice}`}}</span>
                    <div class="amount">{{`x${orderData.orderAmount}`}}</div>
                </div>
            </div>
        </div>
        <div class="money-warp">
            <div class="wrap-item">
                <div>Subtotal</div>
                <div>{{`Rp ${orderData.goodPrice}`}}</div>
            </div>
            <div class="wrap-item">
                <div>Product Quantity</div>
                <div>{{orderData.orderAmount}}</div>
            </div>
            <div class="wrap-item total">
                <div>Total</div>
                <div>{{`Rp ${orderData.goodPrice * orderData.orderAmount}`}}</div>
            </div>
        </div>
        <div class="order-warp">
            <div class="wrap-item">
                <div>Order No.</div>
                <div>{{`${orderData.orderId}`}}</div>
            </div>
            <div class="wrap-item">
                <div>Order Time</div>
                <div>{{formatTime(orderData.createTime / 1000)}}</div>
            </div>
            <div class="wrap-item total">
                <div>Payment Method</div>
                <div>{{orderData.payment_method}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import formatTime from '../../../filters/formatDate.js';
import NavBar from "./navBar.vue";
export default {
    components: { NavBar },
    data(){
        return {
            // data: this.$route.query
            orderData: {
                id: 1,
                orderId: '#0001',
                status: 1,        // 0: 待发货，1: 待收货，2: 已收货
                createTime: Date.now(),
                goodPrice: 9900,
                orderAmount: 1,
                good_title: 'Pulsa GRATIS sebesar Rp5.000,-',
                good_thumbnail: "https://wowssa.s3.ap-southeast-1.amazonaws.com/dev/NewUser_01.png",
                payment_method: '️Cash on delivery'
            },
            addressData: {
                id: 1,
                user_id: 1,
                receiver: 'Tang Jun',
                mobile: '+8617683991415',
                country_region: 'Indonesia Aceh',
                city: 'Jakarta',
                detail_address: 'Jakarta Street 1',
                extend: '',
                postcode: '433200',
                is_default: 0,
            }
        }
    },
    methods: {
      formatTime,
      formatOrderStatus(status){
          let res;
          if(status === 1){
              res = 'Tunggu pengiriman';        // 等待发货
          } else if(status === 2){
              res = '️Menunggu resi';            // 等待收获
          } else if(status === 3){
              res = '️Diterima';                 // 已接受
          } else if(status === 4){
              res = '️Dikembalikan';             // 已退货
          }
          return res;
      },
    }
}
</script>

<style lang="scss" scoped>
.nav-bar, .nav-title{
    background-color: #fff !important;
    color: #000;
}
.orderDetail-container{
    display: block;
    width: 100%;
    min-height: 100%;
    background-color: #f0f5fa;
    .status-detail{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0.8rem 1rem 1.8rem 1rem;
        background-color: #ff8f25;
        color: #fff;
        box-sizing: border-box;
        .status{
            font-size: 24px;
            margin-bottom: 10px;
        }
    }
    .address-info{
        border-radius: 10px;
        position: relative;
        top: -1.2rem;
        margin: 0 0.6rem 0rem 0.6rem;
        padding: 15px;
        background-color: #fff;
        .name_phone{
            font-size: 15px;
            margin: 5px 0;
        }
        span, .desc_info{
            font-size: 13px;
        }
    }
    .good_warp{
        margin: 0 0.6rem;
        padding: 15px;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 10px;
        margin-bottom: 15px;
        .good_content{
            display: flex;
            align-items: center;
            border-radius: 10px;
            .img-container{
                border-radius: 10px;
                width: 100px;
                height: 100px;
                img{
                    max-width: 100%;
                }
            }
        }
        .title{
            margin-left: 10px;
        }
        .price{
            margin-left: 10px;
        }
        .amount{
            text-align: right;
            color: #bbb;
        }
    }
    .money-warp, .order-warp{
        color: #aaa;
        margin: 0 0.6rem;
        padding: 15px;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 10px;
        margin-bottom: 15px;
        .wrap-item{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 15px;
        }
        .total{
            color: #000;
        }
    }
    .order-warp{
        color: #000;
    }
}
</style>